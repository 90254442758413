import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = '/api/user/';
  constructor(private http: HttpClient) {}

  get(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl);
  }

  getById(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + id);
  }

  update(id, data): Observable<any> {
    return this.http.post<any>(this.baseUrl + id, data);
  }

  create(data): Observable<any> {
    return this.http.post<any>(this.baseUrl, data);
  }

  delete(id): Observable<any> {
    return this.http.delete<any>(this.baseUrl + id);
  }
}
