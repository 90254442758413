import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { TestService } from 'src/app/services/test.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent implements OnInit {
  public items = [];
  public kanal = 'Formular';
  public editItem: any = {
    Testdatum: moment().format('YYYY-MM-DDTHH:mm'),
  };
  public collapsed = true;
  public betrieb = '';
  constructor(private testService: TestService) {}

  ngOnInit(): void {}

  addItem() {
    this.editItem.Kanal = this.kanal;
    this.items.push(this.editItem);
    this.editItem = {
      Testdatum: moment().format('YYYY-MM-DDTHH:mm'),
      Bestelldatum: moment().format('YYYY-MM-DDTHH:mm'),
    };

    //this.collapsed = true;
  }

  parseExcel(evt: any) {
    const target: any = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      console.log(data);
      this.convertExcel(data);
    };
    reader.readAsBinaryString(target.files[0]);
    target.value = '';
  }

  convertExcel(data) {
    const results = [];
    if (data.length < 2) {
      return;
    }
    const keys = data[0];
    for (let i = 1; i < data.length; i++) {
      const element = data[i];
      const newItem: any = {};
      keys.forEach((k, i) => {
        newItem[k] = element[i];
      });
      const mails = [];

      newItem.TestId = newItem.Barcode;
      newItem.Geburtsdatum = this.ExcelDateToJSDate(
        newItem.Geburtsdatum,
        false
      );
      newItem.Testdatum = this.ExcelDateToJSDate(newItem.Testdatum, true, newItem.Testzeit);


      if (newItem['E-Mailadresse']) {
        mails.push(newItem['E-Mailadresse']);
      }
      if (newItem['E-Mailadresse 2']) {
        mails.push(newItem['E-Mailadresse 2']);
      }

      newItem.Strasse = newItem['Straße und Nr.'];
      newItem.PLZ = newItem['PLZ'];
      newItem.Ort = newItem['Ort'];
      newItem.Bestelldatum =  moment().format('YYYY-MM-DDTHH:mm');
      if (newItem['Adresse']) {
        newItem['Strasse'] = newItem['Adresse'];
      }

      newItem.Email = mails.join(', ');

      newItem.Tel = newItem['Telefonnummer'];
      newItem.Kanal = this.kanal;

      newItem.Geschlecht = this.parseGender(newItem.Geschlecht)

      newItem.error = newItem.Geburtsdatum ? false : true;
      if(newItem.Vorname) {
        results.push(newItem);
      }
    }

    this.items = [...this.items, ...results];
    this.testItems();
  }

  private parseGender(src) {
    if(!src) {
      return
    }

    let tmpStr = src.toLowerCase();

    if(tmpStr.startsWith('m')) {
      return 'männlich';
    }
    if(tmpStr.startsWith('w')) {
      return 'weiblich'
    }

    return
  }

  changeChannel() {
    this.items.forEach((item) => {
      item.Kanal = this.kanal;
    })
  }

  testItems() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      this.testService.testItem(item).subscribe((res) => {
        item.isDuplicate = res.result;
      });
    }
  }

  deleteAll() {
    this.items = [];
  }

  ExcelDateToJSDate(date, useDefault = true, zeit = null) {
    let cd = moment().format('HH:mm')
    if (!date && !zeit && useDefault) {
      return moment().format('YYYY-MM-DD HH:mm');
    }

    if(zeit) {
      const t = this.ExcelDateToJSTime(zeit);
      cd = t;
    }

    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      const m = moment(date, 'DD.MM.YYYY');
      if (m.isValid()) {
        return m.format('YYYY-MM-DD') + ' ' + cd;
      }
    }
    const d = new Date(Math.round((date - 25569) * 86400 * 1000));
    const mex = moment(d);
    if (mex.isValid()) {
      return mex.format('YYYY-MM-DD') + ' ' + cd;
    }
  }

  private ExcelDateToJSTime(serial) {
    var utc_days  = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return moment().set({hours: hours, minutes: minutes}).format('HH:mm');
 }

  submitData() {
    this.items = this.items
      .map((x) => {
        x.Betrieb = this.betrieb;
        return x;
      })
      .filter((x) => !x.error);

    this.testService.addItems(this.items).subscribe((res) => {
      console.log(res);
      this.items = [];
    });
  }
}
