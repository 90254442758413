import { RolesService } from './../services/roles.service';
import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from '../services/authentification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ITS_JUST_ANGULAR } from '@angular/core/src/r3_symbols';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html',
})
export class FullLayoutComponent implements OnInit {
  public disabled = false;
  public status: { isopen: boolean } = { isopen: false };
  public isAdmin = false;
  public userId = null;
  public roles: any;
  public user: any;
  public userRolesLabels: any;
  public labels: string;
  public showSidebar: Boolean = true;
  public prodNavItems: any = {};

  // public toggled(open: boolean): void {
  //   console.log('Dropdown is now: ', open);
  // }

  // public toggleDropdown($event: MouseEvent): void {
  //   $event.preventDefault();
  //   $event.stopPropagation();
  //   this.status.isopen = !this.status.isopen;
  // }

  public logout() {
    this.authentificationService.logout().subscribe((res) => {
      this.router.navigate(['login']);
    });
  }

  constructor(
    private router: Router,
    private authentificationService: AuthentificationService,
    private rolesService: RolesService
  ) {}

  ngOnInit(): void {
    this.roles = this.rolesService.roles;
    this.buildNavItems();
    // this.isAdmin = this.authenticationServiceService.isAdmin();
    // this.userId = this.authenticationServiceService.getUserId();
    this.user = this.authentificationService.getCurrentUser();
    let userRolesLabels = this.authentificationService.getUserRoleLabels();
    this.userRolesLabels = (userRolesLabels || []).join(', ');
  }

  /**
   * Toggles the sidebar.
   *
   * @memberof FullLayoutComponent
   */
  toggleMenu(): void {
    this.showSidebar = !this.showSidebar;
  }

  /**
   * Builds the items of the subnav
   *
   * @memberof FullLayoutComponent
   */
  buildNavItems(): void {
    let nav = {
      title: 'Produktion',
      icon: 'fas fa-hammer fa-fw',
      items: [
        { label: 'Auftragsübersicht', link: '/order/list' },
        { label: 'Auftragsarchiv', link: '/order/archive' },
      ],
    };
    if (this.roles && this.roles.completeOrder) {
      nav.items.push({
        label: 'Auftragsabschluss',
        link: '/order/approve',
      });
    }
    this.prodNavItems = nav;
  }

  /**
   * Truncates a string if it is longer then max characters.
   *
   * @param {*} str
   * @param {number} [max=10]
   * @return {*}
   * @memberof FullLayoutComponent
   */
  truncate(str, max = 10) {
    const array = str.trim().split(' ');
    const ellipsis = array.length > max ? ' ...' : '';

    return array.slice(0, max).join(' ') + ellipsis;
  }
}
