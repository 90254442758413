import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  private baseUrl = '/api/test/';
  constructor(private http: HttpClient) {}

  get(filter: any): Observable<any[]> {

    return this.http.get<any[]>(this.baseUrl, {
      params: filter
    });
  }

  analytics(filter: any): Observable<any[]> {

    return this.http.get<any[]>(this.baseUrl + 'analytics/sales', {
      params: filter
    });
  }

  getById(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + id);
  }

  update(id, data): Observable<any> {
    return this.http.post<any>(this.baseUrl + id, data);
  }

  create(data): Observable<any> {
    return this.http.post<any>(this.baseUrl, data);
  }

  delete(id): Observable<any> {
    return this.http.delete<any>(this.baseUrl + id);
  }

  undelete(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + id + '/undelete');
  }

  sync(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'sync');
  }

  importWebshop(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'importwebshop');
  }

  importLeadHorizon(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'import-lead-horizon');
  }

  import(ids, auftrag): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'import', {
      ids: ids,
      auftrag: auftrag
    });
  }

  bulkDelete(ids): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'delete', {
      ids: ids
    });
  }

  bulkUnDelete(ids): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'undelete', {
      ids: ids
    });
  }

  barcode(ids): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'label', {
      ids: ids
    });
  }

  addItems(items) {
    return this.http.post<any>(this.baseUrl + 'add', items);
  }

  testItem(item) {
    return this.http.post<any>(this.baseUrl + 'test', item);
  }

}
