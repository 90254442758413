import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthentificationService } from './authentification.service';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
  constructor(
    private authenticationService: AuthentificationService,
    private messageService: MessageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            console.log('reqInterceptor evt', event.url);
          }
        },
        (err: any) => {
          console.log('reqInterceptor err');
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authenticationService.logout();
            }
            console.log('httpError', err);
            this.addError(err.message);
          }
        }
      )
    );
  }

  addError(message: string): void {
    this.messageService.error(message);
  }
}
