import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-subnav',
  templateUrl: './subnav.component.html',
  styleUrls: ['./subnav.component.scss'],
})
export class SubnavComponent implements OnInit {
  @Input() nav: any = {};
  showSubnav: Boolean = false;
  constructor() {}

  ngOnInit(): void {}

  /**
   * Toggles the subnavbar.
   *
   * @memberof SubnavComponent
   */
  toggleNav(): void {
    this.showSubnav = !this.showSubnav;
  }
}
