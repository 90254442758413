import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TestService } from 'src/app/services/test.service';
import * as moment from 'moment';
import { DaterangepickerComponent } from 'ng2-daterangepicker';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit, OnDestroy {
  public filter = {
    startdate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    enddate: moment().format('YYYY-MM-DD'),
    channel: [],
    state: null,
    timeFormat: 'day',
    betrieb: '',
    dateCol: 'Bestelldatum'

  };

  multi: any[];
  view: any[] = [700, 400];
  vorgabe = ['Sichere Gastfreundschaft'];
  betriebe: any[];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Testdatum';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Anzahl an Tests';
  animations: boolean = true;

  colorScheme = {
    domain: ['#607fc0', '#C7B42C', '#5AA454', '#ec8a8a'],
  };

  @Input() channels = ['Webshop', 'Formular', 'Automat', 'Apotheke'];
  public daterange: any = {};
  public autorefresh: false;
  public lastRefresh: any;
  private interval

  public dateoptions: any = {
    locale: { format: 'DD.MM.YYYY', customRangeLabel: 'Benutzerdefiniert' },
    alwaysShowCalendars: false,
    ranges: {
      'Letzte 6 Monate': [
        moment().subtract(6, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
      'Letzte 3 Monate': [
        moment().subtract(3, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
      'Letzter Monat': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
      'Letzten 30 Tage': [moment().subtract(31, 'days'), moment()],
      'Letzten 7 Tage': [moment().subtract(7, 'days'), moment()],
      Heute: [moment(), moment()],
      Morgen: [moment().add(1, 'days'), moment().add(1, 'days')],
      'Nächsten 7 Tage': [moment(), moment().add(7, 'days')],
      'Nächsten 30 Tage': [moment(), moment().add(31, 'days')],
      'Aktueller Monat': [moment().startOf('month'), moment().endOf('month')],
      'Nächster Monat': [
        moment().add(1, 'month').startOf('month'),
        moment().add(1, 'month').endOf('month'),
      ],
    },
  };

  @ViewChild(DaterangepickerComponent)
  private picker: DaterangepickerComponent;

  constructor(private testService: TestService) {
    this.filter.startdate =
      this.dateoptions.ranges['Nächsten 7 Tage'][0].format('YYYY-MM-DD');
    this.filter.enddate = this.dateoptions.ranges['Nächsten 7 Tage'][1].format('YYYY-MM-DD');

    this.filter.channel = this.channels;
    this.filter.state = 'null'
  }

  ngOnInit(): void {
    this.fetch();
    this.interval = setInterval(() => {
      if(!this.autorefresh) {
        return;
      }
      console.log('refresh');
      this.fetch();
    }, 1000 * 60)
  }

  ngOnDestroy(): void {
    if(this.interval) {
      clearInterval(this.interval);
    }
  }

  search(event) {
    console.log(event);
    const tmpBetriebe = this.vorgabe.filter((x) => {
      return x.indexOf(event.query) >= 0;
    });

    this.betriebe = [event.query, ...tmpBetriebe]
}


  onDateChange(event, datepicker?) {
    console.log(datepicker);
    this.filter.startdate = event.start.format('YYYY-MM-DD');
    this.filter.enddate = event.end.format('YYYY-MM-DD');
    this.fetch();
  }

  prevDay() {
    const startdate = moment(this.filter.startdate).subtract(1, 'day');

    this.filter.startdate = startdate.format('YYYY-MM-DD');
    this.picker.datePicker.setStartDate(startdate);
    const enddate = moment(this.filter.enddate).subtract(1, 'day');
    this.filter.enddate = enddate.format('YYYY-MM-DD');

    this.picker.datePicker.setEndDate(enddate);
    this.fetch();
  }

  nextDay() {
    const startdate = moment(this.filter.startdate).add(1, 'day');
    this.filter.startdate = startdate.format('YYYY-MM-DD');

    this.picker.datePicker.setStartDate(startdate);
    const enddate = moment(this.filter.enddate).add(1, 'day');
    this.filter.enddate = enddate.format('YYYY-MM-DD');

    this.picker.datePicker.setEndDate(enddate);
    this.fetch();
  }

  channelChange() {
    this.fetch();
  }

  fetch() {
    this.testService.analytics(this.filter).subscribe((res) => {
      //console.log(res);
      this.lastRefresh = moment().format('DD.MM:YYYY HH:mm')
      this.xAxisLabel = this.filter.dateCol;
      this.multi = res.map((r) => {
        const item = {
          name: r.Datum,
          series: r.series,
        };

        return item;
      });
    });
  }
}
