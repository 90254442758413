import { ConfigService } from './../../../services/config.service';
import { AuthentificationService } from './../../../services/authentification.service';
import { UserService } from './../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../../services/message.service';
import { RolesService } from '../../../services/roles.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  //if userId == new --> create user
  //else edit user
  id: string;
  newItem: boolean;
  departments: any[] = [];
  departments_dropdown: any[];
  data: any = {
    fistName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    groups: [],
    department: 0,
  };
  loading: Boolean = false;
  roles: any;
  currentUser: any;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    private rolesService: RolesService,
    private authentificationService: AuthentificationService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.roles = this.rolesService.roles;
    this.departments_dropdown = this.configService.getConfigByGroup(
      'department'
    ).fields;
    if (this.departments_dropdown) {
      this.departments_dropdown.forEach((el) => {
        this.departments[el.id] = [el.label];
      });
    }
    console.log('departments', this.departments_dropdown);
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      if (this.id === 'new') {
        this.newItem = true;
        return;
      }
      this.currentUser = this.authentificationService.getCurrentUser();
      this.getData();
    });
  }

  /**
   * Fetches data of the user.
   *
   * @memberof UserComponent
   */
  getData() {
    this.loading = true;
    this.data = null;
    this.userService.getById(this.id).subscribe((res: any) => {
      this.data = res;
      if (this.data.department === null) {
        this.data.department = 0;
      }
      this.loading = false;
    });
  }

  /**
   * Updates user in data base with the current data and gives success message.
   *
   * @memberof UserComponent
   */
  update() {
    this.loading = true;
    if (this.data.department === '0' || this.data.department === 0) {
      this.data.department = null;
    }
    this.userService.update(this.id, this.data).subscribe((res: any) => {
      this.data = res;
      this.loading = false;
      this.messageService.success('Benutzer erfolgreich geändert');
    });
  }

  /**
   * Creates new user in the data base, returns success message and
   * changes to the edit mode of the user details.
   *
   * @memberof UserComponent
   */
  create() {
    this.loading = true;
    if (this.data.department === '0') {
      this.data.department = null;
    }
    this.userService.create(this.data).subscribe((res: any) => {
      this.data = res;
      this.id = this.data.id;
      this.newItem = false;
      this.messageService.success('Benutzer erfolgreich erstellt');
      this.router.navigate(['/user', this.data.id]);
      this.loading = false;
    });
  }
}
