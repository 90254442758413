import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LOCALE } from './shared/locale_de';

@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  constructor(private config: PrimeNGConfig) {}
  ngOnInit() {
    this.config.setTranslation(LOCALE);
  }
}
