<p-confirmDialog
  [style]="{ width: '50vw' }"
  baseZIndex="10000"
  acceptButtonStyleClass="editButton"
  rejectButtonStyleClass="editButton"
></p-confirmDialog>
<p-table
  #dt
  [value]="users"
  styleClass="p-datatable-customers p-datatable-striped"
  [globalFilterFields]="[
    'id',
    'fistName',
    'lastName',
    'department',
    'email',
    'status'
  ]"
>
  <ng-template pTemplate="caption">
    <div class="ng-star-inserted tableheader">
      <div class="p-d-flex ng-star-inserted insidetableheader">
        <span class="p-input-icon-left p-ml-auto spanheader">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            class="p-inputtext p-component"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Suche"
          />
        </span>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of cols" [pSortableColumn]="col.field">
        {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
      <th>
        <a
          class="btn createButton mr-2"
          [routerLink]="['/user', 'new']"
          *ngIf="roles.createUser"
        >
          <i class="fas fa-plus"></i>
        </a>
        <button class="btn btn-info" (click)="getData()">
          <i class="fas fa-sync"></i>
        </button>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr>
      <td *ngFor="let col of cols">
        <ng-container [ngSwitch]="col.field">
          <ng-container *ngSwitchCase="'department'">
            {{ departments[user[col.field]] }}
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ user[col.field] }}
          </ng-container></ng-container
        >
      </td>
      <td>
        <button
          class="btn editButton mr-2"
          [routerLink]="['/user', user.id]"
          *ngIf="roles.editUser"
        >
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button
          class="btn deleteButton"
          *ngIf="roles.deleteUser"
          (click)="deleteConfirmationDialog(user.id)"
        >
          <i class="far fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
