import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

// routing
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// custom services
import { AuthentificationService } from './services/authentification.service';
import { RequestInterceptorService } from './services/request-interceptor.service';
import { ConfigService } from './services/config.service';

// import 3rd party
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { NgxLoadingModule } from 'ngx-loading';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { Daterangepicker } from 'ng2-daterangepicker';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {FieldsetModule} from 'primeng/fieldset';
import {DialogModule} from 'primeng/dialog';
import {TooltipModule} from 'primeng/tooltip';
import {MultiSelectModule} from 'primeng/multiselect';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {AutoCompleteModule} from 'primeng/autocomplete';


// import components
import { FullLayoutComponent } from './layouts/full-layout.component';
import { UserComponent } from './components/user/user/user.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { SubnavComponent } from './layouts/subnav/subnav.component';
import { TestListComponent } from './components/test-list/test-list.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';

// initialize application; load config
export function initialize(configService: ConfigService) {
  return () => {
    return new Promise((resolve) => {
      configService.load().then((val) => {
        resolve(true);
      });
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    UserComponent,
    UserListComponent,
    DashboardComponent,
    LoginComponent,
    SubnavComponent,
    TestListComponent,
    RegistrationFormComponent,
    AnalyticsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    TableModule,
    HttpClientModule,
    FormsModule,
    DialogModule,
    ConfirmDialogModule,
    TabViewModule,
    NgxLoadingModule.forRoot({}),
    MessagesModule,
    MessageModule,
    CheckboxModule,
    ToastModule,
    CalendarModule,
    InputTextareaModule,
    DropdownModule,
    AccordionModule,
    Daterangepicker,
    ProgressSpinnerModule,
    FieldsetModule,
    TooltipModule,
    MultiSelectModule,
    NgxChartsModule,
    AutoCompleteModule
  ],
  providers: [
    AuthentificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    MessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [ConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
