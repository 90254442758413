import { Injectable } from '@angular/core';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  public roles: any;
  public rolesLabels: any;

  constructor(private authentificationService: AuthentificationService) {
    this.roles = this.authentificationService.getPermissions();
    this.authentificationService.onUserChanged().subscribe((user) => {
      this.roles = this.authentificationService.getPermissions();
    });
  }
}
