<form id="myForm">
  <div class="form-group">
    <label for="company">Firma</label>
    <input
      type="text"
      class="form-control"
      id="company"
      aria-describedby="emailHelp"
      placeholder="Firmenbezeichnung"
      [(ngModel)]="betrieb"
      name="betrieb"
    />
    <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
  </div>

  <div class="form-group">
    <label for="company">Kanal</label>
    <select
      type="text"
      class="form-control"
      id="channel"
      [(ngModel)]="kanal"
      (change)="changeChannel()"
      name="kanal"
      required
    >
      <option value="Formular">Formular</option>
      <option value="Apotheke">Apotheke</option>
    </select>
    <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
  </div>

  <table class="table">
    <thead>
      <th>Kanal</th>
      <th>Barcode</th>
      <th>Name</th>
      <th>Geschlecht</th>
      <th>Geburtsdatum</th>
      <th>Passnummer</th>
      <th>Kontakt</th>
      <th>Testdatum</th>
      <th>
        <button class="btn btn-warning" (click)="deleteAll()">
          <i class="fas fa-trash-alt"></i>
        </button>
      </th>
    </thead>
    <tbody>
      <tr
        *ngFor="let item of items; let i = index"
        [ngClass]="{ disabled: item.isDuplicate, error: item.error }"
      >
        <td>
          {{ item.Kanal }}
        </td>
        <td>
          <i *ngIf="item.error" class="fa fa-exclamation-triangle"></i>
          <i *ngIf="item.isDuplicate" class="fa fa-clone"></i>
          {{ item.TestId || "-" }}
        </td>
        <td>
          {{ item.Nachname }} {{ item.Vorname }} <br />
          {{ item.Strasse }} {{ item.PLZ }} {{ item.Ort }} {{ item.Land }}
        </td>
        <td>{{item.Geschlecht}}</td>
        <td>{{ item.Geburtsdatum | date: "dd.MM.YYYY" }}</td>
        <td>{{ item.Passnummer || "-" }}</td>
        <td>
          {{ item.Email }} <br />
          {{ item.Tel }}
        </td>
        <td>{{ item.Testdatum | date: "dd.MM.YYYY HH:mm" }}</td>
        <td>
          <button class="btn btn-warning" (click)="items.splice(i, 1)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <button
    [disabled]="items.length === 0"
    class="btn btn-info"
    (click)="submitData()"
  >
    Absenden
  </button>
</form>

<p-tabView>
  <p-tabPanel header="Dokument hochladen" [selected]="true">
    <input
      class="custom-file-input"
      type="file"
      id="file"
      (change)="parseExcel($event)"
    />
    <label for="file">Excel hochladen</label>
  </p-tabPanel>
  <p-tabPanel header="Manuelle Eingabe">
    <form #userForm="ngForm">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="nachname">Nachname</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            id="Nachname"
            name="Nachname"
            placeholder="Nachname"
            [(ngModel)]="editItem.Nachname"
            #Nachname="ngModel"
            required
            [ngClass]="{ 'form-control-danger': !Nachname.valid }"
          />
        </div>
        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="nachname">Vorname</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            id="Vorname"
            name="Vorname"
            placeholder="Vorname"
            [(ngModel)]="editItem.Vorname"
            #Vorname="ngModel"
            required
            [ngClass]="{ 'form-control-danger': !Vorname.valid }"
          />
        </div>
        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="Email">Email</label>
        <div class="col-sm-10">
          <input
            type="Email"
            class="form-control"
            id="Email"
            name="Email"
            placeholder="Email"
            [email]="true"
            [(ngModel)]="editItem.Email"
            #Email="ngModel"
            required
            [ngClass]="{ 'form-control-danger': !Email.valid }"
          />
        </div>
        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="nachname"
          >Geburtsdatum</label
        >
        <div class="col-sm-10">
          <input
            type="date"
            class="form-control"
            id="Geburtsdatum"
            name="Geburtsdatum"
            placeholder="Geburtsdatum"
            [(ngModel)]="editItem.Geburtsdatum"
            #Geburtsdatum="ngModel"
            required
            [ngClass]="{ 'form-control-danger': !Geburtsdatum.valid }"
          />
        </div>
        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="nachname">Testdatum</label>
        <div class="col-sm-10">
          <input
            type="datetime-local"
            class="form-control"
            id="Testdatum"
            name="Testdatum"
            placeholder="Geburtsdatum"
            [(ngModel)]="editItem.Testdatum"
            #Testdatum="ngModel"
            required
            [ngClass]="{ 'form-control-danger': !Testdatum.valid }"
          />
        </div>
        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="Geschlecht">Geschlecht</label>
        <div class="col-sm-10">
          <select
            class="form-control"
            id="Geschlecht"
            name="Geschlecht"
            placeholder="Geburtsdatum"
            [(ngModel)]="editItem.Geschlecht"
            #Geschlecht="ngModel"
            required
            [ngClass]="{ 'form-control-danger': !Geschlecht.valid }"
          >
        <option value="männlich">männlich</option>
        <option value="weiblich">weiblich</option>
        </select>
        </div>
        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
      </div>
      <button
        [disabled]="!userForm.form.valid"
        class="btn btn-info"
        (click)="addItem()"
      >
        Hinzufügen
      </button>
    </form>
  </p-tabPanel>
</p-tabView>
