const LOCALE = {
  startsWith: 'Startet mit',
  contains: 'Beinhaltet',
  notContains: 'Beinhaltet nicht',
  endsWith: 'Endet mit',
  equals: 'Ist gleich',
  notEquals: 'Ist nicht gleich',
  noFilter: 'Kein Filter',
  lt: 'Kleiner als',
  lte: 'Kleiner oder gleich',
  gt: 'Größer als',
  gte: 'Größer oder gleich',
  is: 'Ist',
  isNot: 'Ist nicht',
  before: 'Vor',
  after: 'Nach',
  clear: 'Löschen',
  apply: 'Anwenden',
  matchAll: 'Stimmt mit allen überein',
  matchAny: 'Stimmt mit keinem überein',
  addRule: 'Regel hinzufügen',
  removeRule: 'Regel entfernen',
  accept: 'Ja',
  reject: 'Nein',
  choose: 'Wähle',
  upload: 'Upload',
  cancel: 'Abbrechen',
  dayNames: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
  dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  monthNames: [
    'Jänner',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  monthNamesShort: [
    'Jän',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
  today: 'Heute',
  weekHeader: 'Wk',
};

export { LOCALE };
