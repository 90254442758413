import { Injectable } from '@angular/core';
import { MessageService as PrimeMessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private messageService: PrimeMessageService) {}

  warn(message: string): void {
    this.messageService.add({
      severity: 'warn',
      summary: 'Achtung',
      detail: message,
    });
  }

  info(message: string): void {
    this.messageService.add({
      severity: 'info',
      summary: 'Info',
      detail: message,
    });
  }

  success(message: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Erfoglreich',
      detail: message,
    });
  }

  error(message: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Fehler',
      detail: message,
    });
  }
}
