<header class="navbar sticky-top flex-md-nowrap shadow">
  <!-- <button
    (click)="toggleMenu()"
    class="navbar-toggler"
    id="menu-toggle"
    type="button"
  >
    <span class="navbar-toggler-icon"></span>
  </button> -->

  <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">
    <img src="assets/images/procomLogo.png" />
  </a>
  <button
    class="navbar-toggler position-absolute d-md-none collapsed"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#sidebarMenu"
    aria-controls="sidebarMenu"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
    <li class="nav-item dropdown">
      <div class="btn-group" dropdown>
        <a
          class="nav-link dropdown-toggle"
          id="navbarDropdown"
          dropdownToggle
          type="button"
          aria-controls="dropdown-basic"
        >
          <div class="pull-right navbar-text navbarTextCustom">
            <i class="fa-1x usericon">
              {{ user.firstName | slice: 0:1 }}{{ user.lastName | slice: 0:1 }}
            </i>
            <div class="row userArea d-none d-lg-block">
              <div class="col-md-12 font-weight-bold">
                {{ user.firstName }} {{ user.lastName }}
              </div>
              <div class="col-md-12 permissionArea small">
                {{ truncate(userRolesLabels, 2) }}
              </div>
            </div>
            <span class="caret"></span>
          </div>
        </a>
        <div
          id="dropdown-basic"
          *dropdownMenu
          class="dropdown-menu dropdown-menu-right"
          role="menu"
          aria-labelledby="navbarDropdown"
        >
          <a class="dropdown-item" [routerLink]="['/user', user.id]">Profil</a>
          <div class="dropdown-divider"></div>
          <span class="dropdown-item cursorPointer" (click)="logout()">
            Abmelden
          </span>
        </div>
      </div>
    </li>
  </ul>
</header>

<div class="d-flex" id="wrapper">
  <nav
    id="sidebarMenu"
    class="bg-light sidebar collapse"
    [ngClass]="showSidebar ? 'showMenu' : 'toggleMenu'"
  >
    <div class="position-sticky pt-3">
      <ul class="nav flex-column">
        <li class="nav-item custom-nav">
          <a
            class="nav-link nav-header"
            [routerLink]="['/dashboard']"
            [routerLinkActive]="['active']"
          >
            <i class="men-icon fas fa-cloud-upload-alt fa-fw"></i>
            Kundenimport
          </a>
          <a
            class="nav-link nav-header"
            [routerLink]="['/formulare']"
            [routerLinkActive]="['active']"
          >
            <i class="men-icon fas fa-table fa-fw"></i>
            Tests Formular
          </a>
          <a
            class="nav-link nav-header"
            [routerLink]="['/shop']"
            [routerLinkActive]="['active']"
          >
            <i class="men-icon fas fa-shopping-cart fa-fw"></i>
            Tests Webshop
          </a>
          <a
            class="nav-link nav-header"
            [routerLink]="['/automat']"
            [routerLinkActive]="['active']"
          >
            <i class="men-icon fas fa-robot fa-fw"></i>
            Tests Automat
          </a>
          <a
            class="nav-link nav-header"
            [routerLink]="['/apotheke']"
            [routerLinkActive]="['active']"
          >
            <i class="men-icon fas fa-first-aid fa-fw"></i>
            Tests Apotheke
          </a>
          <!-- <a
            class="nav-link nav-header"
            [routerLink]="['/leadhorizon']"
            [routerLinkActive]="['active']"
          >
            <i class="men-icon fas fa-robot fa-fw"></i>
            Lead Horizon
          </a> -->
          <a
            class="nav-link nav-header"
            [routerLink]="['/analytics']"
            [routerLinkActive]="['active']"
            *ngIf="roles && roles.analytics"
          >
            <i class="men-icon fas fa-chart-line fa-fw"></i>
            Analyse
          </a>
        </li>

        <li class="nav-item custom-nav">
          <a
            class="nav-link nav-header"
            [routerLink]="['/user']"
            [routerLinkActive]="['active']"
            *ngIf="
              roles && (roles.createUser || roles.editUser || roles.deletUser)
            "
          >
            <i class="men-icon fas fa-user fa-fw"></i>
            Benutzer
          </a>
        </li>
      </ul>
    </div>
  </nav>

  <main id="maincontent" class="">
    <div class="">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<p-toast [baseZIndex]="999"></p-toast>
