import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public message: String = '';

  constructor() {}

  ngOnInit(): void {
    let now = new Date();
    const hours = now.getHours();
    if (hours <= 10) {
      this.message = 'Guten Morgen!';
    } else if (hours <= 17) {
      this.message = 'Willkommen!';
    } else {
      this.message = 'Guten Abend!';
    }
  }
}
