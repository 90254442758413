<div class="container h-100">
  <div class="row h-100">
    <div class="col-12 align-self-center">
      <div class="card w-50 mx-auto">
        <div class="card-body">
          <h1>Anmeldung</h1>
          <p class="text-muted">Bitte melden Sie sich an</p>

          <label class="sr-only" for="username">Username</label>
          <div class="input-group mb-2 mr-sm-2">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-user"></i>
              </div>
            </div>

            <input
              type="text"
              class="form-control"
              placeholder="Benutzername"
              id="username"
              [(ngModel)]="username"
            />
          </div>

          <label class="sr-only" for="password">Kennwort</label>
          <div class="input-group mb-2 mr-sm-2">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-lock"></i>
              </div>
            </div>

            <input
              type="password"
              class="form-control"
              placeholder="Kennwort"
              id="password"
              [(ngModel)]="password"
            />
          </div>

          <div class="row justify-content-md-center" [hidden]="!errmessage">
            <div
              class="col-8 alert alert-danger"
              role="alert"
              style="text-align: center"
            >
              {{ errmessage }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <button
                type="button"
                class="btn btn-primary px-4 loginButton"
                (click)="login()"
              >
                Anmelden
              </button>
            </div>
            <!--<div class="col-6 text-right">
                  <button type="button" class="btn btn-link px-0">Forgot password?</button>
                </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '14px' }"
  ></ngx-loading>
</div>
