import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthentificationService } from '../../services/authentification.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public username: String;
  public password: String;
  returnUrl: string;
  loading: boolean = false;
  errmessage: string = null;

  constructor(
    private authenticationService: AuthentificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['return'] || '/';
  }

  login() {
    this.loading = true;
    this.errmessage = null;
    this.authenticationService
      .login(this.username, this.password)
      .subscribe((res) => {
        console.log(res);
        if (res.success === true) {
          this.router.navigateByUrl(this.returnUrl);
        } else {
          this.errmessage = res.message;
        }
        this.loading = false;
      });
  }
}
