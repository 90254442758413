<li class="nav-item custom-nav">
  <a
    class="nav-link nav-header cursorPointer"
    aria-current="page"
    (click)="toggleNav()"
    [ngClass]="showSubnav ? 'active' : ''"
  >
    <i class="men-icon {{ nav.icon }}" *ngIf="nav.icon"></i>
    {{ nav.title }}
    <i class="men-arrow fas fa-angle-right" *ngIf="!showSubnav"></i>
  </a>
  <ul class="submenu" [ngClass]="showSubnav ? 'show' : 'hide'">
    <li *ngFor="let item of nav.items">
      <a
        class="nav-link"
        aria-current="page"
        [routerLinkActive]="['active']"
        [routerLink]="[item.link]"
      >
        {{ item.label }}
      </a>
    </li>
  </ul>
</li>
