import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  CanActivate,
  CanActivateChild,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';
import { Roles, RoleLabels } from '../shared/roles';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationService implements CanActivate {
  private baseUrl = '/auth/';
  private user;
  private permissions: Array<string> = [];
  private userChanged: EventEmitter<any>;

  constructor(private httpClient: HttpClient, private router: Router) {
    this.userChanged = new EventEmitter();
  }

  /**
   * login method
   *
   * @param {*} username
   * @param {*} password
   * @return {*}
   * @memberof AuthentificationService
   */
  login(username, password) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: header,
    };
    const urlSearchParams = {
      username: username,
      password: password,
    };

    return this.httpClient
      .post(this.baseUrl + 'login', urlSearchParams, options)
      .pipe(
        map((response: any) => {
          this.user = response;
          this.permissions = this.user.groups;
          if (response && response.token) {
            localStorage.setItem('currentUser', JSON.stringify(response));
          }
          return this.user;
        })
      );
  }

  /**
   * logout method
   *
   * @return {*}
   * @memberof AuthentificationService
   */
  logout() {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login'], {});
    return this.httpClient.get(this.baseUrl + 'logout');
  }

  /**
   * checks if user is authorized
   *
   * @return {*}
   * @memberof AuthentificationService
   */
  isAuthorized() {
    return this.httpClient.get(this.baseUrl);
  }

  /**
   * returns changed user
   *
   * @return {*}
   * @memberof AuthentificationService
   */
  onUserChanged() {
    return this.userChanged;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isAuthorized().pipe(
      map((res: any) => {
        const data = res;
        if (data.auth) {
          this.permissions = data.user.groups;
          this.user = data.user;
          this.userChanged.emit(this.user);
          return true;
        }
        this.router.navigate(['/login']);
        return false;
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }

  getCurrentUser() {
    return this.user;
  }

  getToken() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      return currentUser.token;
    }
  }

  getPermissions() {
    let p = {};
    this.permissions.forEach((value) => {
      let role = Roles[value];
      if (role === undefined) return;
      role.forEach((role) => {
        p[role] = true;
      });
    });
    return p;
  }

  getUserRoleLabels() {
    let roles = this.user.groups;
    let labels = [];
    roles.forEach((element) => {
      if (RoleLabels && RoleLabels[element]) {
        labels.push(RoleLabels[element]);
      }
    });
    return labels;
  }
}
