import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TestService } from 'src/app/services/test.service';
import * as moment from 'moment';
import { DaterangepickerComponent } from 'ng2-daterangepicker';
import { MessageService } from 'src/app/services/message.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-test-list',
  templateUrl: './test-list.component.html',
  styleUrls: ['./test-list.component.scss'],
})
export class TestListComponent implements OnInit {
  public data: any[] = [];
  filterUpdate = new Subject<string>();
  public syncingAutomat = false;
  public syncingWebshop = false;
  public syncingLeadHorizon = false;

  public filter: any = {
    state: 'false',
  };
  public importWs = false;
  public importAutomat = false;
  public importLeadHorizon = false;

  @Input() channels = ['Webshop', 'Formular', 'Automat', 'LeadHorizon'];
  //public channels = [{name: 'Webshop', value: 'Webshop'}, {name: 'Formular', value: 'Formular'},{name: 'Automat', value: 'Automat'} ]
  public editItem: any = {};
  public displayDialog = false;
  public selectedTests: any[] = [];
  public auftrag: string;
  public daterange: any = {};
  public sum = {
    synced: 0,
    total: 0,
    notSynced: 0,
  }
  public dateoptions: any = {
    locale: { format: 'DD.MM.YYYY', customRangeLabel: 'Benutzerdefiniert' },
    alwaysShowCalendars: false,
    ranges: {
      Heute: [moment(), moment()],
      Morgen: [moment().add(1, 'days'), moment().add(1, 'days')],
      'Nächsten 7 Tage': [moment(), moment().add(7, 'days')],
      'Nächsten 30 Tage': [moment(), moment().add(31, 'days')],
      'Aktueller Monat': [moment().startOf('month'), moment()],
      'Nächster Monat': [
        moment().add(1, 'month').startOf('month'),
        moment().add(1, 'month').endOf('month'),
      ],
    },
  };

  @ViewChild(DaterangepickerComponent)
  private picker: DaterangepickerComponent;
  constructor(
    private testService: TestService,
    private messageService: MessageService,
    private route: ActivatedRoute
  ) {
    route.data.subscribe((d) => {
      console.log(d);
      if (d.channel) {
        this.channels = d.channel;
        this.filter.channel = d.channel;
      }
      this.importAutomat = this.channels.indexOf('Automat') >= 0;
      this.importWs = this.channels.indexOf('Webshop') >= 0;
      this.importLeadHorizon = this.channels.indexOf('LeadHorizon') >= 0;
    });
    this.filterUpdate
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        console.log('search');
        this.fetch();
      });

    this.filter.startdate =
      this.dateoptions.ranges.Heute[0].format('YYYY-MM-DD');
    this.filter.enddate = this.dateoptions.ranges.Heute[1].format('YYYY-MM-DD');
  }

  ngOnInit(): void {
    this.fetch();
  }

  onDateChange(event, datepicker?) {
    console.log(datepicker);
    this.filter.startdate = event.start.format('YYYY-MM-DD');
    this.filter.enddate = event.end.format('YYYY-MM-DD');
    this.fetch();
  }

  prevDay() {
    const startdate = moment(this.filter.startdate).subtract(1, 'day');

    this.filter.startdate = startdate.format('YYYY-MM-DD');
    this.picker.datePicker.setStartDate(startdate);
    const enddate = moment(this.filter.enddate).subtract(1, 'day');
    this.filter.enddate = enddate.format('YYYY-MM-DD');

    this.picker.datePicker.setEndDate(enddate);
    this.fetch();
  }

  nextDay() {
    const startdate = moment(this.filter.startdate).add(1, 'day');
    this.filter.startdate = startdate.format('YYYY-MM-DD');

    this.picker.datePicker.setStartDate(startdate);
    const enddate = moment(this.filter.enddate).add(1, 'day');
    this.filter.enddate = enddate.format('YYYY-MM-DD');

    this.picker.datePicker.setEndDate(enddate);
    this.fetch();
  }

  channelChange() {
    this.fetch();
  }

  onFilter(event) {
    this.sum = event.filteredValue.reduce(
      (acc, t) => {
        if (t.Synced) {
          acc.synced++;
        } else {
          acc.notSynced++;
        }

        acc.total++;

        return acc;
      },
      {
        synced: 0,
        total: 0,
        notSynced: 0,
      }
    );

  }

  fetch() {
    if (this.filter.state === 'deleted') {
    }
    this.testService.get(this.filter).subscribe((res) => {
      console.log(res);
      this.data = res.map((t) => {
        t.addrTxt = `${t.Nachname} ${t.Vorname} ${t.Strasse} ${t.PLZ} ${t.Ort} ${t.Land}`;
        return t;
      });


      this.selectedTests = this.selectedTests.filter((st) => {
        const item = this.data.find((d) => d.id === st.id);
        if (item) {
          return true;
        }
        return false;
      });
    });
  }

  sync() {
    this.messageService.info('Datenabruf gestartet');
    this.syncingAutomat = true;
    this.testService.sync().subscribe((res) => {
      if (res.result) {
        this.messageService.info(`${res.result} neue Einträge hinzugefügt`);
      } else {
        this.messageService.info('Keine neuen Einträge');
      }
      this.syncingAutomat = false;
      this.fetch();
    });
  }

  syncWebshop() {
    this.messageService.info('Datenabruf gestartet');
    this.syncingWebshop = true;
    this.testService.importWebshop().subscribe((res) => {
      if (res.result) {
        this.messageService.info(`${res.result} neue Einträge hinzugefügt`);
      } else {
        this.messageService.info('Keine neuen Einträge');
      }
      this.syncingWebshop = false;
      this.fetch();
    });
  }

  syncLeadHorizon() {
    this.messageService.info('Datenabruf gestartet');
    this.syncingLeadHorizon = true;
    this.testService.importLeadHorizon().subscribe((res) => {
      if (res.result) {
        this.messageService.info(`${res.result} neue Einträge hinzugefügt`);
      } else {
        this.messageService.info('Keine neuen Einträge');
      }
      this.syncingLeadHorizon = false;
      this.fetch();
    });
  }

  importData() {
    this.messageService.info('Import gestartet');
    //this.syncing = true;
    console.log(this.selectedTests);
    const ids = this.selectedTests
      .filter((x) => x.Synced === null)
      .map((x) => x.id);
    this.testService.import(ids, this.auftrag).subscribe((res) => {
      if (res.result.success) {
        this.messageService.info(`Daten erfolgreich importiert`);
        //const url = location.origin + '/csvimports/' + res.result
        //window.open(url, 'blank');
      } else {
        this.messageService.error('Fehler beim import');
      }
      //this.syncing = false;
      this.fetch();
      this.selectedTests = [];
    });
  }

  deleteTest(id) {
    this.testService.delete(id).subscribe((res) => {
      this.fetch();
      this.messageService.info('Eintrag gelöscht');
    });
  }

  unDeleteTest(id) {
    this.testService.undelete(id).subscribe((res) => {
      this.fetch();
      this.messageService.info('Eintrag wiederhergestellt');
    });
  }
  deleteSelectedTests() {
    const ids = this.selectedTests
      .filter((x) => x.Synced === null)
      .map((x) => x.id);
    this.testService.bulkDelete(ids).subscribe((res) => {
      this.selectedTests = [];
      this.fetch();
      this.messageService.info('Einträge gelöscht');
    });
  }

  createCode4SelectedTests() {
    this.messageService.info(
      'Labels werden erstellt, und in einem neuen Fenster geöffnet'
    );
    const ids = this.selectedTests
      .filter((x) => x.Synced === null)
      .map((x) => x.id);
    this.testService.barcode(ids).subscribe((res) => {
      //this.selectedTests = [];
      this.fetch();
      this.messageService.info('Labels erstellt');
      window.open('tmp/myOutput.pdf', '_blank');
    });
  }

  restoreSelectedTests() {
    const ids = this.selectedTests
      .filter((x) => x.Deleted !== null)
      .map((x) => x.id);
    this.testService.bulkUnDelete(ids).subscribe((res) => {
      this.selectedTests = [];
      this.fetch();
      this.messageService.info('Einträge wiederherhgestellt');
    });
  }

  editTest(test) {
    test.Testdatum = moment(test.Testdatum).format('YYYY-MM-DDTHH:mm');
    test.Geburtsdatum = moment(test.Geburtsdatum).format('YYYY-MM-DD');

    //test.TestDatum = new Date()

    this.editItem = test;
    this.displayDialog = true;
  }

  saveItem() {
    console.log(this.editItem);
    this.testService
      .update(this.editItem.id, this.editItem)
      .subscribe((res) => {
        this.fetch();
        this.editItem = {};
        this.displayDialog = false;
      });
  }
}
