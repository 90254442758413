<div class="form-row">
  <!-- <div class="col">
    <label for="TestId">Barcode</label>
    <input
      id="ID"
      type="text"
      class="form-control"
      [(ngModel)]="filter.TestId"
      (ngModelChange)="this.filterUpdate.next($event)"
    />
  </div> -->
  <div class="col" style="max-width: 150px">
    <label for="TestId">Status</label>
    <select
      class="form-control"
      name="state"
      id="state"
      [(ngModel)]="filter.state"
      (change)="fetch()"
    >
      <option value="true">Importiert</option>
      <option value="false">Neu</option>
      <option value="null">Alle</option>
      <option value="deleted">gelöscht</option>
    </select>
  </div>
  <div class="col" style="max-width: 150px">
    <label for="TestId">Kanal</label>
    <p-multiSelect
      class="form-control"
      id="channel"
      [options]="channels"
      [(ngModel)]="filter.channel"
      (onChange)="channelChange()"
    >
    </p-multiSelect>
  </div>
  <div class="col" style="max-width: 350px">
    <label for="TestId">Testdatum</label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" (click)="prevDay()">
          <i class="fas fa-arrow-left"></i>
        </div>
      </div>
      <input
        type="text"
        class="form-control"
        name="daterangeInput"
        daterangepicker
        [options]="dateoptions"
        (selected)="onDateChange($event, daterange)"
      />
      <div class="input-group-append">
        <div class="input-group-text" (click)="nextDay()">
          <i class="fas fa-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="col" style="max-width: 350px">
    <label for="dateCol">Datumtyp</label>
    <select
      class="form-control"
      name="dateCol"
      id="dateCol"
      [(ngModel)]="filter.dateCol"
      (change)="fetch()"
    >
      <option value="Bestelldatum">Bestelldatum</option>
      <option value="Testdatum">Testdatum</option>
    </select>
  </div>
  <div class="col" style="max-width: 350px">
    <label for="Betrieb">Betrieb</label>
    <p-autoComplete
    class="form-control"
      name="betrieb"
      id="betrieb"
      [(ngModel)]="filter.betrieb"
      [suggestions]="betriebe"
      (completeMethod)="search($event)"
      (onSelect)="fetch()"
    ></p-autoComplete>
  </div>
  <div class="col" style="max-width: 350px">
    <label for="TestId">Gruppierung</label>
    <select
      class="form-control"
      name="timeFormat"
      id="timeFormat"
      [(ngModel)]="filter.timeFormat"
      (change)="fetch()"
    >
      <option value="day">Tag</option>
      <option value="week">Woche</option>
      <option value="month">Monat</option>
    </select>
  </div>
  <div class="col" style="max-width: 150px">
    <label (click)="fetch()" for="Refesh">Autorefresh</label>
    <p-checkbox
      class="form-control"
      [binary]="true"
      [(ngModel)]="autorefresh"
    ></p-checkbox>
  </div>
  <div class="col" style="max-width: 150px">
    <label>{{ lastRefresh }}</label>
    <button class="p-button" (click)="fetch()">Refresh</button>
  </div>

  <!-- <div class="col">
    <button pButton (click)="fetch()" icon="pi pi-search"></button>
  </div> -->
</div>

<div class="chart-container">
  <ngx-charts-bar-vertical-stacked
    [scheme]="colorScheme"
    [results]="multi"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [animations]="animations"
    [legendPosition]="'right'"
  >
  </ngx-charts-bar-vertical-stacked>
</div>
