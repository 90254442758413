import { ConfigService } from './../../../services/config.service';
import { UserService } from './../../../services/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { RolesService } from '../../../services/roles.service';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [ConfirmationService],
})
export class UserListComponent implements OnInit {
  public cols: any[];
  public users: any[];
  public departments: any[] = [];
  public roles: any;
  public loading: Boolean = false;

  constructor(
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private rolesService: RolesService,
    private messageService: MessageService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.roles = this.rolesService.roles;
    let deps = this.configService.getConfigByGroup('department').fields;
    if (deps) {
      deps.forEach((el) => {
        this.departments[el.id] = [el.label];
      });
    }
    this.cols = [
      { field: 'id', header: 'Nummer' },
      { field: 'firstName', header: 'Vorname' },
      { field: 'lastName', header: 'Nachname' },
      { field: 'department', header: 'Abteilung' },
      { field: 'email', header: 'Kontakt' },
    ];

    this.getData();
  }

  /**
   * Fetches data of all users.
   *
   * @memberof UserListComponent
   */
  getData() {
    this.loading = true;
    this.userService.get().subscribe((res: any) => {
      this.users = res;
      this.loading = false;
    });
  }

  /**
   * Deletes the user with id.
   *
   * @param {*} id
   * @memberof UserListComponent
   */
  deleteUser(id) {
    this.loading = true;
    this.userService.delete(id).subscribe((res) => {
      console.log('users after deleting', res);
      this.getData();
      this.loading = false;
    });
  }

  /**
   * Confirmation dialog of deletion of the user with id.
   *
   * @param {*} id
   * @memberof UserListComponent
   */
  deleteConfirmationDialog(id) {
    let deleteUser: any;
    this.userService.getById(id).subscribe((data) => {
      deleteUser = data;
      this.confirmationService.confirm({
        message:
          'Möchtest du den Benutzer ' +
          deleteUser.firstName +
          ' ' +
          deleteUser.lastName +
          ' wirklich löschen?',
        header: 'Benutzer löschen',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        accept: () => {
          this.deleteUser(id);
          this.messageService.success('Benutzer erfolgreich gelöscht');
        },
        reject: () => {},
      });
    });
  }
}
