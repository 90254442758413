<ngx-loading [show]="loading" [config]="{}"></ngx-loading>

<p-tabView>
  <p-tabPanel header="Benutzerdaten">
    <form #userForm="ngForm">
      <div class="row" *ngIf="data">
        <div class="col-sm-12 col-lg-6">
          <div class="form-group" *ngIf="!newItem">
            <label for="id">Nummer</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nummer"
              name="id"
              id="id"
              pInputText
              required
              disabled="true"
              [(ngModel)]="data.id"
            />
          </div>
          <div class="form-group">
            <label for="userName">Benutzername</label>
            <input
              type="text"
              class="form-control"
              placeholder="Benutzername"
              name="userName"
              id="userName"
              pInputText
              required
              [disabled]="!newItem"
              [(ngModel)]="data.userName"
            />
          </div>
          <div class="form-group">
            <label for="email">E-Mail</label>
            <input
              type="email"
              class="form-control"
              placeholder="Kontakt"
              name="email"
              id="email"
              pInputText
              required
              [(ngModel)]="data.email"
              [disabled]="!roles.editUser && id != currentUser.id"
            />
          </div>
          <!-- <div class="form-group">
            <label for="department">Abteilung</label>
            <select
              [(ngModel)]="data.department"
              [disabled]="!roles.editUser"
              id="department"
              name="department"
              class="form-control"
            >
              <option [value]="0">Bitte wählen...</option>
              <option
                *ngFor="let item of departments_dropdown"
                [value]="item.id"
              >
                {{ item.label }}
              </option>
            </select>
          </div> -->
        </div>
        <div class="col-sm-12 col-lg-6">
          <div class="form-group">
            <label for="firstName">Vorname</label>
            <input
              type="text"
              class="form-control"
              placeholder="Vorname"
              name="firstName"
              id="firstName"
              pInputText
              required
              [(ngModel)]="data.firstName"
              [disabled]="!roles.editUser && id != currentUser.id"
            />
          </div>
          <div class="form-group">
            <label for="lastName">Nachname</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nachname"
              name="lastName"
              id="lastName"
              pInputText
              required
              [(ngModel)]="data.lastName"
              [disabled]="!roles.editUser && id != currentUser.id"
            />
          </div>
          <div class="form-group">
            <label for="password">Passwort</label>
            <input
              type="password"
              class="form-control"
              placeholder="Passwort"
              name="password"
              id="password"
              pInputText
              required
              [(ngModel)]="data.password"
              [disabled]="!roles.editUser && id != currentUser.id"
            />
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="userGroup" class="col-sm-2 col-form-label">
          {{ "Benutzergruppe" }}
        </label>
        <div class="col-sm-10"></div>
      </div>
      <div class="form-group form-inline col-12" *ngIf="data?.groups">
        <p-checkbox
          class="col-3"
          name="groups"
          value="MANAGER"
          [(ngModel)]="data.groups"
          label="Administrator"
          [disabled]="!roles.editUser"
        ></p-checkbox>
        <p-checkbox
          class="col-3"
          name="groups"
          value="WORKER"
          [(ngModel)]="data.groups"
          label="Office"
          [disabled]="!roles.editUser"
        ></p-checkbox>
      </div>
      <div class="row tabfooter">
        <div class="col-sm-12 col-lg-6">
          <button class="btn submitButton" *ngIf="newItem" (click)="create()">
            Erstellen
          </button>
          <button
            class="btn submitButton"
            *ngIf="!newItem"
            (click)="update()"
            [disabled]="
              !userForm.form.valid || (!roles.editUser && id != currentUser.id)
            "
          >
            Speichern
          </button>
        </div>
        <div class="col-sm-12 col-lg-6">
          <button
            class="btn deleteButton"
            [routerLink]="['/user']"
            *ngIf="roles.createUser || roles.editUser"
          >
            Zurück zur Übersicht
          </button>
        </div>
      </div>
    </form>
  </p-tabPanel>
</p-tabView>
