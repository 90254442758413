const Roles = {
  MANAGER: [
    // Benutzer
    'editUser',
    'createUser',
    'deleteUser',
    'analytics'
  ],
  WORKER: [
    // Auftragsübersicht
    'orderListWorker',
    'acceptOrder',
    // Auftragsdetails
    'orderDetailsWorker',
    'packOrder',
  ],
  TESTER: [
    // Auftragsübersicht
    'orderListTester',
    // Auftragsdetails
    'orderDetailsTester',
    'testUnits',
  ],
};

const RoleLabels = {
  MANAGER: 'Administrator',
  WORKER: 'Mitarbeiter',
};

export { Roles, RoleLabels };
