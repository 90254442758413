import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthentificationService } from './services/authentification.service';
import { FullLayoutComponent } from './layouts/full-layout.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { TestListComponent } from './components/test-list/test-list.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { UserComponent } from './components/user/user/user.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'shop',
    pathMatch: 'full',
    canActivate: [AuthentificationService],
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthentificationService],
    canActivateChild: [AuthentificationService],
    data: {
      title: 'Home',
    },
    children: [
      {
        path: 'dashboard',
        component: RegistrationFormComponent,
        data: {
          title: 'Dashboard',
        },
      },
      {
        path: 'formulare',
        component: TestListComponent,
        data: {
          title: 'Formular',
          channel: ['Formular'],
        },
      },
      {
        path: 'shop',
        component: TestListComponent,
        data: {
          title: 'Webshop',
          channel: ['Webshop'],
          importButtons: true,
        },
      },
      {
        path: 'automat',
        component: TestListComponent,
        data: {
          title: 'Automaten',
          channel: ['Automat'],
          importButtons: true,
        },
      },
      {
        path: 'apotheke',
        component: TestListComponent,
        data: {
          title: 'Apotheke',
          channel: ['Apotheke'],
          importButtons: false,
        },
      },
      // {
      //   path: 'leadhorizon',
      //   component: TestListComponent,
      //   data: {
      //     title: 'LeadHorizon',
      //     channel: ['LeadHorizon'],
      //     importButtons: false,
      //   },
      // },
      {
        path: 'tests',
        component: TestListComponent,
        data: {
          title: 'Tests',
        },
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
        data: {
          title: 'Analyse',
        },
      },
      {
        path: 'user',
        component: UserListComponent,
        data: {
          title: 'Benutzer',
        },
      },
      {
        path: 'user/:id',
        component: UserComponent,
        data: {
          title: 'Benutzer',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
