<p-table
  id="testtable"
  [value]="data"
  [responsive]="true"
  [autoLayout]="true"
  [(selection)]="selectedTests"
  styleClass="p-datatable-sm"
  (onFilter)="onFilter($event)"
>
  <ng-template pTemplate="header">
    <tr>
      <th colspan="15">
        <div class="form-row">
          <!-- <div class="col">
            <label for="TestId">Barcode</label>
            <input
              id="ID"
              type="text"
              class="form-control"
              [(ngModel)]="filter.TestId"
              (ngModelChange)="this.filterUpdate.next($event)"
            />
          </div> -->
          <div class="col" style="max-width: 150px">
            <label for="TestId">Status</label>
            <select
              class="form-control"
              name="state"
              id="state"
              [(ngModel)]="filter.state"
              (change)="fetch()"
            >
              <option value="true">Importiert</option>
              <option value="false">Neu</option>
              <option value="null">Alle</option>
              <option value="deleted">gelöscht</option>
            </select>
          </div>
          <div class="col" style="max-width: 150px">
            <label for="TestId">Kanal</label>
            <p-multiSelect
              class="form-control"
              id="channel"
              [options]="channels"
              [(ngModel)]="filter.channel"
              (onChange)="channelChange()"
            >
            </p-multiSelect>
          </div>
          <div class="col" style="max-width: 350px">
            <label for="TestId">Testdatum</label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text" (click)="prevDay()">
                  <i class="fas fa-arrow-left"></i>
                </div>
              </div>
              <input
                type="text"
                class="form-control"
                name="daterangeInput"
                daterangepicker
                [options]="dateoptions"
                (selected)="onDateChange($event, daterange)"
              />
              <div class="input-group-append">
                <div class="input-group-text" (click)="nextDay()">
                  <i class="fas fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <label for="TestId">Auftragsnummer</label>
            <input
              class="form-control"
              [(ngModel)]="auftrag"
              name="auftrag"
              required
            />
          </div>
          <div class="col">
            <label for="TestId">Aktionen</label>
            <div>
              <button
                [disabled]="selectedTests.length === 0 || !auftrag"
                class="p-button"
                (click)="importData()"
              >
                Daten importieren
              </button>
              <button
                *ngIf="importAutomat"
                class="p-button pull-right"
                (click)="sync()"
              >
                <span style="margin-right: 5px">
                  <i
                    [ngClass]="{ 'fa-spin': syncingAutomat }"
                    class="fas fa-sync"
                  ></i>
                </span>
                Automaten
              </button>
              <button
                *ngIf="importWs"
                class="p-button pull-right"
                (click)="syncWebshop()"
              >
                <span style="margin-right: 5px">
                  <i
                    [ngClass]="{ 'fa-spin': syncingWebshop }"
                    class="fas fa-sync"
                  ></i>
                </span>
                Webshop
              </button>
              <!-- <button
              *ngIf="importLeadHorizon"
              class="p-button pull-right"
              (click)="syncLeadHorizon()"
            >
              <span style="margin-right: 5px">
                <i [ngClass]="{'fa-spin': syncingLeadHorizon}" class="fas fa-sync"></i>
              </span>
              Lead Horizon
            </button> -->
            </div>
          </div>
          <!-- <div class="col">
            <button pButton (click)="fetch()" icon="pi pi-search"></button>
          </div> -->
        </div>
      </th>
    </tr>
    <tr>
      <!-- <th style="width: 50px"></th>
      <th style="width: 150px">
      </th> -->
      <th colspan="2">Summe: {{ sum.total }}</th>
      <th>
        <p-columnFilter
          style="width: 150px"
          type="text"
          field="Auftrag"
          [showMenu]="false"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th style="width: 50px">
        <p-columnFilter
          type="text"
          field="Terminal"
          [showMenu]="false"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="TestId"
          [showMenu]="false"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th></th>
      <th style="width: 180px"></th>
      <th>
        <p-columnFilter
          type="text"
          field="addrTxt"
          [showMenu]="false"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th></th>
      <th></th>
      <th>
        <p-columnFilter
          type="text"
          field="Betrieb"
          [showMenu]="false"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <!-- <th>Adresse</th> -->
      <th></th>
      <th></th>
      <th></th>
      <th style="width: 150px">
        <button pButton (click)="fetch()" icon="pi pi-refresh"></button>
      </th>
    </tr>
    <tr>
      <th style="width: 50px">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th style="width: 150px" pSortableColumn="Kanal">
        <p-sortIcon field="Kanal"></p-sortIcon>
        Kanal
      </th>
      <th style="width: 150px" pSortableColumn="Auftrag">
        <p-sortIcon field="Auftrag"></p-sortIcon>
        Auftrag
      </th>
      <th>Terminal</th>
      <th>ID</th>
      <th style="width: 180px" pSortableColumn="Bestelldatum">
        <p-sortIcon field="Bestelldatum"></p-sortIcon>
        Best.-Datum
      </th>
      <th style="width: 180px" pSortableColumn="Testdatum">
        <p-sortIcon field="Testdatum"></p-sortIcon>
        Testdatum / Geschlecht
      </th>
      <th pSortableColumn="addrTxt" style="width: 250px">
        <p-sortIcon field="addrTxt"></p-sortIcon>
        Name/Adresse
      </th>
      <th style="width: 150px" pSortableColumn="Geburtsdatum">
        <p-sortIcon field="addrTxt"></p-sortIcon> Geb.-datum
      </th>
      <th>SVNr / Passnummer</th>
      <th style="width: 250px" pSortableColumn="Betrieb">
        <p-sortIcon field="Betrieb"></p-sortIcon> Betrieb
      </th>
      <!-- <th>Adresse</th> -->
      <th>Kontakt</th>
      <th>LST</th>
      <th>Tests</th>
      <th style="width: 150px">
        <button
          class="btn btn-success mr-2"
          *ngIf="selectedTests.length > 0"
          (click)="createCode4SelectedTests()"
        >
          <i class="fas fa-print"></i>
        </button>
        <button
          class="btn deleteButton"
          *ngIf="filter.state !== 'deleted' && selectedTests.length > 0"
          (click)="deleteSelectedTests()"
        >
          <i class="far fa-trash-alt"></i>
        </button>
        <button
          class="btn deleteButton"
          *ngIf="filter.state === 'deleted' && selectedTests.length > 0"
          (click)="restoreSelectedTests()"
        >
          <i class="fas fa-trash-restore"></i>
        </button>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-test>
    <tr [ngClass]="{ deleted: test.Deleted }">
      <td style="width: 50px">
        <p-tableCheckbox
          *ngIf="test.Synced === null"
          [value]="test"
        ></p-tableCheckbox>
        <i
          [pTooltip]="test.Synced"
          tooltipPosition="left"
          showDelay="1000"
          *ngIf="test.Synced"
          class="fas fa-check"
        ></i>
        <i
          style="margin: 2px; font-size: 14px"
          [pTooltip]="test.Deleted"
          tooltipPosition="left"
          showDelay="1000"
          *ngIf="test.Deleted"
          class="fas fa-trash"
        ></i>
        <i
          style="margin: 2px; font-size: 14px"
          [pTooltip]="test.LBDReport"
          tooltipPosition="left"
          showDelay="1000"
          *ngIf="test.LBDReport"
          class="fas fa-envelope"
        ></i>
      </td>
      <td>{{ test.Kanal }}</td>
      <td>{{ test.Auftrag }}</td>
      <td>{{ test.Terminal }}</td>
      <td>{{ test.TestId }}</td>
      <td>{{ test.Bestelldatum | date: "dd.MM.YYYY HH:mm" }}</td>
      <td>
        {{ test.Testdatum | date: "dd.MM.YYYY HH:mm" }}<br />{{
          test.Geschlecht || "-"
        }}
      </td>
      <td>
        {{ test.Nachname }} {{ test.Vorname }}<br />
        {{ test.Strasse }} {{ test.PLZ }} {{ test.Ort }} {{ test.Land }}
      </td>
      <td>{{ test.Geburtsdatum | date: "dd.MM.y":"+0000" }}</td>
      <td>
        {{ test.SVNr || "-" }} <br />
        {{ test.Passnummer || "-" }}
      </td>
      <td>{{ test.Betrieb }}</td>
      <td>
        {{ test.Email }}<br />
        {{ test.Tel }}
      </td>
      <td>{{ test.LST }}</td>
      <td>
        <span *ngIf="test.PCRTest" class="badge bg-info">PCR</span>
        <span *ngIf="test.AGTest" class="badge bg-info">AG</span>
        <span *ngIf="test.ANTTest" class="badge bg-info">ANT</span>
      </td>
      <td>
        <button
          class="btn editButton mr-2"
          (click)="editTest(test)"
          *ngIf="!test.Synced"
        >
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button
          class="btn deleteButton"
          *ngIf="!test.Synced && !test.Deleted"
          (click)="deleteTest(test.id)"
        >
          <i class="fas fa-trash-alt"></i>
        </button>
        <button
          class="btn deleteButton"
          *ngIf="!test.Synced && test.Deleted"
          (click)="unDeleteTest(test.id)"
        >
          <i class="fas fa-trash-restore"></i>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog
  header="Eintrag bearbeiten"
  [(visible)]="displayDialog"
  style="z-index: 10000"
>
  <form #userForm="ngForm" style="width: 700px">
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="nachname">Barcode</label>
      <div class="col-sm-9">
        <input
          type="text"
          class="form-control"
          id="TestId"
          name="TestId"
          placeholder="TestId"
          [(ngModel)]="editItem.TestId"
          #TestId="ngModel"
          [ngClass]="{ 'form-control-danger': !TestId.valid }"
        />
      </div>
      <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="nachname">Nachname</label>
      <div class="col-sm-9">
        <input
          type="text"
          class="form-control"
          id="Nachname"
          name="Nachname"
          placeholder="Nachname"
          [(ngModel)]="editItem.Nachname"
          #Nachname="ngModel"
          required
          [ngClass]="{ 'form-control-danger': !Nachname.valid }"
        />
      </div>
      <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="nachname">Vorname</label>
      <div class="col-sm-9">
        <input
          type="text"
          class="form-control"
          id="Vorname"
          name="Vorname"
          placeholder="Vorname"
          [(ngModel)]="editItem.Vorname"
          #Vorname="ngModel"
          required
          [ngClass]="{ 'form-control-danger': !Vorname.valid }"
        />
      </div>
      <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="Email">Email</label>
      <div class="col-sm-9">
        <input
          type="text"
          class="form-control"
          id="Email"
          name="Email"
          placeholder="Email"
          [(ngModel)]="editItem.Email"
          #Email="ngModel"
          required
          [ngClass]="{ 'form-control-danger': !Email.valid }"
        />
      </div>
      <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="Tel">Tel</label>
      <div class="col-sm-9">
        <input
          type="tel"
          class="form-control"
          id="Tel"
          name="Tel"
          placeholder="Tel"
          [(ngModel)]="editItem.Tel"
          #Tel="ngModel"
          [ngClass]="{ 'form-control-danger': !Tel.valid }"
        />
      </div>
      <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="Tel">Passnummer</label>
      <div class="col-sm-9">
        <input
          type="tel"
          class="form-control"
          id="Passnummer"
          name="Passnummer"
          placeholder="Passnummer"
          [(ngModel)]="editItem.Passnummer"
          #Passnummer="ngModel"
          [ngClass]="{ 'form-control-danger': !Passnummer.valid }"
        />
      </div>
      <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="Geburtsdatum"
        >Geburtsdatum</label
      >
      <div class="col-sm-9">
        <input
          type="date"
          class="form-control"
          id="Geburtsdatum"
          name="Geburtsdatum"
          placeholder="Geburtsdatum"
          [(ngModel)]="editItem.Geburtsdatum"
          #Geburtsdatum="ngModel"
          required
          [ngClass]="{ 'form-control-danger': !Geburtsdatum.valid }"
        />
      </div>
      <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="Testdatum">Testdatum</label>
      <div class="col-sm-9">
        <input
          type="datetime-local"
          class="form-control"
          id="Testdatum"
          name="Testdatum"
          placeholder="Testdatum"
          [(ngModel)]="editItem.Testdatum"
          #Testdatum="ngModel"
          required
          [ngClass]="{ 'form-control-danger': !Testdatum.valid }"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="Geschlecht">Geschlecht</label>
      <div class="col-sm-9">
        <select
          class="form-control"
          id="Geschlecht"
          name="Geschlecht"
          placeholder="Geburtsdatum"
          [(ngModel)]="editItem.Geschlecht"
          #Geschlecht="ngModel"
          required
          [ngClass]="{ 'form-control-danger': !Geschlecht.valid }"
        >
          <option value="männlich">männlich</option>
          <option value="weiblich">weiblich</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="Strasse">Straße</label>
      <div class="col-sm-9">
        <input
          type="text"
          class="form-control"
          id="Strasse"
          name="Strasse"
          placeholder="Straße"
          [(ngModel)]="editItem.Strasse"
          #Strasse="ngModel"
          [ngClass]="{ 'form-control-danger': !Strasse.valid }"
        />
      </div>
      <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="PLZ">PLZ</label>
      <div class="col-sm-9">
        <input
          type="text"
          class="form-control"
          id="PLZ"
          name="PLZ"
          placeholder="PLZ"
          [(ngModel)]="editItem.PLZ"
          #PLZ="ngModel"
          [ngClass]="{ 'form-control-danger': !PLZ.valid }"
        />
      </div>
      <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="Ort">Ort</label>
      <div class="col-sm-9">
        <input
          type="text"
          class="form-control"
          id="Ort"
          name="Ort"
          placeholder="Ort"
          [(ngModel)]="editItem.Ort"
          #Ort="ngModel"
          [ngClass]="{ 'form-control-danger': !Ort.valid }"
        />
      </div>
      <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
    <button
      [disabled]="!userForm.form.valid"
      class="btn btn-info"
      (click)="saveItem()"
    >
      Aktualisieren
    </button>
  </form>
</p-dialog>
