import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config: any;
  private baseUrl = '/api/configuration/';

  constructor(private http: HttpClient) {}

  /**
   * get system configuration
   *
   * @return {*}
   * @memberof ConfigService
   */
  public loadSystemConfig() {
    return this.http.get(this.baseUrl);
  }

  /**
   * loads the system config if needed
   *
   * @return {*}  {Promise<any>}
   * @memberof ConfigService
   */
  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadSystemConfig().subscribe((res: any) => {
        this.config = res;
        resolve(true);
      });
    });
  }

  /**
   * get the group of the system config with name
   *
   * @param {*} name
   * @return {*}
   * @memberof ConfigService
   */
  getConfigByGroup(name) {
    const item = this.config.find((el) => el.key === name);
    if (item) {
      return item;
    }
    return false;
  }
}
